import React from "react"
import { graphql, useStaticQuery } from "gatsby";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import Steps from "../components/Steps.js";
import GetInTouch from "../components/GetInTouch";

import { joinUsFeaturedContent, BecomeAPartnerHeroDetails } from "../constants";

const JoinUs = () => {

  const data = useStaticQuery(graphql`
    query {
      heroImage: allFile(filter: { name: { in: "become-a-partner-v5" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
      featuredContentImages: allFile(
        filter: {
          name: {
            in: [
              "01-intact-kit-sponsorship"
              "03-burnham-banner-sponsorship"
              "06-src-racing"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  //console.log("Data - Sponsorship: ", data);

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Intact Electrical Ltd - Join us",
        "url":  "https://intact-electrical.co.uk/join-us",
        "logo": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif"
      })}
    </script>
  )

  const featuredContentImages = data.featuredContentImages.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = BecomeAPartnerHeroDetails;

  return (
    <>
      <Seo       
        title="Join us"
        description="Intact Electrical, Join us, Collaborate, partner with us, Partnership, how it works, Get started let's connect"
        post={false}
        metaRobots="nofollow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={joinUsFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <Steps />
      <GetInTouch />
    </>
  );
};

export default JoinUs;
